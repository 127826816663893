<template>
  <div class="p404">
    <div class="content">
      <strong>404</strong>
      <p>UH OH! 页面不存在</p>
      <span>{{ time }}s 返回首页</span>
      <router-link to="/">立即返回首页</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: 5
    }
  },
  mounted() {
    const timer = setInterval(() => {
      this.time--
      if (this.time === 0) {
        clearInterval(timer)
        this.$router.replace('/')
      }
    }, 1000)
  }
}
</script>

<style lang="less" scoped>
.p404 {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    height: 500px;
    max-width: 400px;
  }
  strong {
    font-size: 120px;
    font-weight: 900;
  }
  p {
    font-size: 32px;
    font-weight: 900;
    margin: 10px 0 20px;
  }
  a {
    display: inline-block;
    margin-left: 20px;
    font-size: 16px;
    line-height: 1;
    padding: 10px 15px;
    background-color: red;
    border-radius: 5px;
    color: white;
    transition: opacity 0.3;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
